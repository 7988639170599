.bg-light-primary {
	background-color: rgb(13 110 253 / 0.11) !important;
  }
  
  .bg-light-success {
	background-color: rgb(23 160 14 / 0.11) !important;
  }
  
  .bg-light-danger {
	background-color: rgb(244 17 39 / 0.11) !important;
  }
  
  .bg-light-warning {
	background-color: rgb(255 193 7 / 0.11) !important;
  }
  
  .bg-light-info {
	background-color: rgb(13 202 240 / 18%) !important;
  }
  
  .bg-gradient-cosmic {
	background: linear-gradient(to right, #8e2de2, #4a00e0) !important;
  }
  
  .bg-gradient-burning {
	background: linear-gradient(to right, #ff416c, #ff4b2b) !important;
  }
  
  .bg-gradient-lush {
	background: linear-gradient(to right, #56ab2f, #a8e063) !important;
  }
  
  .bg-gradient-kyoto {
	background: linear-gradient(to right, #f7971e, #ffd200) !important;
  }
  
  .bg-gradient-blues {
	background: linear-gradient(to right, #56ccf2, #2f80ed) !important;
  }
  
  .bg-gradient-moonlit {
	background: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
  }
  
  .split-bg-primary {
	background-color: #0c62e0;
	border-color: #0c62e0;
  }
  
  .split-bg-secondary {
	background-color: #515a62;
	border-color: #515a62;
  }
  
  .split-bg-success {
	background-color: #128e0a;
	border-color: #128e0a;
  }
  
  .split-bg-info {
	background-color: #0bb2d3;
	border-color: #0bb2d3;
  }
  
  .split-bg-warning {
	background-color: #e4ad07;
	border-color: #e4ad07;
  }
  
  .split-bg-danger {
	background-color: #e20e22;
	border-color: #e20e22;
  }
  
  .bg-facebook {
	background-color: #3b5998 !important;
  }
  
  .bg-twitter {
	background-color: #55acee !important;
  }
  
  .bg-google {
	background-color: #e52d27 !important;
  }
  
  .bg-linkedin {
	background-color: #0976b4 !important;
  }

  
.bg-light-primary {
	background-color: rgb(13 110 253 / .11)!important
}

.bg-light-success {
	background-color: rgb(23 160 14 / .11)!important
}

.bg-light-danger {
	background-color: rgb(244 17 39 / .11)!important
}

.bg-light-warning {
	background-color: rgb(255 193 7 / .11)!important
}

.bg-light-info {
	background-color: rgb(13 202 240 / 18%)!important
}

.bg-light-transparent {
	background-color: rgb(0 0 0 / 15%)!important
}

.bg-gradient-deepblue {
    background: #6a11cb;
    background: -webkit-linear-gradient(
	45deg
	, #6a11cb, #2575fc)!important;
		background: linear-gradient(
	45deg
	, #6a11cb , #2575fc)!important;
}


.bg-gradient-orange {
    background: #fc4a1a;
    background: -webkit-linear-gradient(
	45deg
	, #fc4a1a, #f7b733)!important;
		background: linear-gradient(
	45deg
	, #fc4a1a, #f7b733)!important;
}

.bg-gradient-ohhappiness {
    background: #00b09b;
    background: -webkit-linear-gradient(
	45deg
	, #00b09b, #96c93d)!important;
		background: linear-gradient(
	45deg
	, #00b09b, #96c93d)!important;
}


.bg-gradient-ibiza {
    background: #ee0979;
    background: -webkit-linear-gradient(
	45deg
	, #ee0979, #ff6a00)!important;
		background: linear-gradient(
	45deg
	, #ee0979, #ff6a00)!important;
}

.bg-gradient-scooter {
    background: #17ead9;
    background: -webkit-linear-gradient(
45deg
, #17ead9, #6078ea)!important;
    background: linear-gradient(
45deg
, #17ead9, #6078ea)!important;
}


.bg-gradient-bloody {
    background: #f54ea2;
    background: -webkit-linear-gradient(
45deg
, #f54ea2, #ff7676)!important;
    background: linear-gradient(
45deg
, #f54ea2, #ff7676)!important;
}


.bg-gradient-quepal {
    background: #42e695;
    background: -webkit-linear-gradient(
45deg
, #42e695, #3bb2b8)!important;
    background: linear-gradient(
45deg
, #42e695, #3bb2b8)!important;
}


.bg-gradient-blooker {
    background: #ffdf40;
    background: -webkit-linear-gradient(
45deg
, #ffdf40, #ff8359)!important;
    background: linear-gradient(
45deg
, #ffdf40, #ff8359)!important;
}


.bg-gradient-cosmic {
	background: linear-gradient(to right, #8e2de2, #4a00e0)!important
}

.bg-gradient-burning {
	background: linear-gradient(to right, #ff416c, #ff4b2b)!important
}

.bg-gradient-lush {
	background: linear-gradient(to right, #56ab2f, #a8e063)!important
}

.bg-gradient-kyoto {
	background: linear-gradient(to right, #f7971e, #ffd200)!important
}

.bg-gradient-blues {
	background: linear-gradient(to right, #56ccf2, #2f80ed)!important
}

.bg-gradient-moonlit {
	background: linear-gradient(to right, #0f2027, #203a43, #2c5364)!important
}

.split-bg-primary {
	background-color: #0c62e0;
	border-color: #0c62e0
}

.split-bg-secondary {
	background-color: #515a62;
	border-color: #515a62
}

.split-bg-success {
	background-color: #128e0a;
	border-color: #128e0a
}

.split-bg-info {
	background-color: #0bb2d3;
	border-color: #0bb2d3
}

.split-bg-warning {
	background-color: #e4ad07;
	border-color: #e4ad07
}

.split-bg-danger {
	background-color: #e20e22;
	border-color: #e20e22
}

.bg-facebook {
	background-color: #3b5998!important
}

.bg-twitter {
	background-color: #55acee!important
}

.bg-google {
	background-color: #e52d27!important
}

.bg-linkedin {
	background-color: #0976b4!important
}

/* Text Color */

.text-option{
	color: #32393f!important
 }

.text-facebook{
	color: #3b5998!important
 }
 
 .text-twitter{
	 color: #55acee!important
  }
 
  .text-youtube{
	 color: #e52d27!important
  }

.text-sky-light {
    color: #b4d2ff;
}

