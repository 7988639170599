/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~bootstrap-icons/font/bootstrap-icons.css";

:root {
  --main-font: "Helvetica";
  --sidebar-width: 250px;
  --app-primary-color: #e90064;
}

body {
  font-family: var(--main-font);
}


.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color:#B9B0A7;
  pointer-events: none;
  background-color:#E9E5E2;
  border-color: #E9E5E2;
  opacity: var(--bs-btn-disabled-opacity);
}
